<template>
  <div class="flex wrap flex-col justify-center items-center py-6" align="center">
    <v-col>
      <v-window v-model="window" class="elevation-0" horizontal>
        <v-window-item v-for="(n, i) in userData.doyouknow" :key="i">
          <section class="bg-white py-3 xs:px-2 md:px-6">
            <div
              class="w-full grid xs:grid-cols-1  gap-3 md:grid-cols-2"
            >
              <div class="know_text xs:order-last xs:text-center md:text-left md:order-first flex flex-col justify-center gap-2 xs:items-center md:items-start">
                <p class="ma-0 xs:text-center md:text-left pa-0 text-2xl font-sans font-bold">
                  Do You Know ?
                </p>
                <p v-html="n.text" class="ma-0 pa-0 xs:text-center md:text-left font-serif  text-2xl">
                 
                </p>
              
              </div>

            
              <div class=" flex-1 xs:order-first md:order-last flex flex-col overflow-hidden justify-center items-center  ">  <img width="400" class="object-fit-cover rounded shadow-sm" :src="n.img" alt=""></div>
            
            </div>
          </section>
        </v-window-item>
      </v-window>
    </v-col>
    <v-item-group v-model="window" class="shrink mr-6" mandatory tag="v-flex">
      <v-item v-for="n  in length" :key="n" v-slot="{ active, toggle }">
        <div>
          <v-btn :input-value="active" icon @click="toggle">
            <v-icon>mdi-record</v-icon>
          </v-btn>
        </div>
      </v-item>
    </v-item-group>
  </div>
</template>

<script>
export default {
  name: "doyouknow",
  props: ['userData'],
  computed:{
    length(){
      return [...Array(this.userData.doyouknow.length).keys()]
    }
  },
  data: () => ({
    model: null,
    window: 0,
  }),
  methods: {},
};
</script>

<style scoped>

.wrap{
    border-top: 2px solid rgba(128, 128, 128, 0.693);
}
.box {
  width: 100% !important;
}
</style>
