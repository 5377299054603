import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAg_g31RqrD8uhAZxVbR2h84afeFhq_jSw",
  authDomain: "apwa-dd0a0.firebaseapp.com",
  projectId: "apwa-dd0a0",
  storageBucket: "apwa-dd0a0.appspot.com",
  messagingSenderId: "314626935194",
  appId: "1:314626935194:web:33138653932528475dd223"
};

// Initialize Firebase
let app = initializeApp(firebaseConfig);
initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore();

export { app, auth, db };
