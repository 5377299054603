<template>
<div>
    <bannerVue v-if="!standalone" :title="title"></bannerVue>
    <div class="my-4 px-6 mx-auto">
<!-- Section: Design Block -->
<section class="mb-32 text-gray-800 text-center">

  <h2 class="text-3xl font-bold mb-12 pb-4 text-center">Latest articles</h2>

  <div class="grid lg:grid-cols-3 gap-6 xl:gap-x-12">
    <div v-for="(i,n) in !standalone ? articles : articles.slice(0,3)" :key="n" class="mb-6 lg:mb-0">
      <div class="relative block bg-white rounded-lg shadow-lg">
        <div class="flex">
          <div
            class="bg-cover relative overflow-hidden h-48 bg-no-repeat  shadow-lg rounded-lg mx-4 -mt-4 flex justify-center items-center"
            data-mdb-ripple="true" data-mdb-ripple-color="light">
            <img class="object-cover w-full" :src="i.img"/>
            <a href="#!">
              <div
                class="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-100 transition duration-300 ease-in-out"
                style="background-color: rgba(251, 251, 251, 0.15)"></div>
            </a>
          </div>
        </div>
        <div class="p-6">
          <h5 class="font-bold text-lg mb-3">{{ i.title }}</h5>
          <p class="text-gray-500 mb-4">
            <small>Published  by
              <a href="" class="text-gray-900">Admin</a></small>
          </p>
          <p v-html="i.shortDes" class="mb-4 pb-2">
            
          </p>
          <v-btn :to="{name: 'ArticleView', params: { articleData: { ...i } } }" color="blue" class="white--text">Read More</v-btn>
        </div>
      </div>
    </div>
  </div>

</section>
<!-- Section: Design Block -->

</div>
</div>
</template>

<script>
import bannerVue from "./utils/banner.vue";

export default {
    name: 'blog',
    props:['standalone', 'articles'],
    data: ()=>({
        title: 'Articles',
    
    }),
    components:{
        bannerVue
    }
}
</script>

<style>

</style>