import Vue from "vue";

import VueRouter from "vue-router";
import goTo from "vuetify/lib/services/goto";

import HomeView from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/stories",
    name: "Stories",
    component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Stories.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
          import(/* webpackChunkName: "about" */ "@/views/About.vue"),
  },
  {
    path: "/blog",
    name: "Blog",
    component: () =>
          import(/* webpackChunkName: "about" */ "@/components/blog.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Admin.vue"),
  },
  {
    path: "/articleView",
    name: "ArticleView",
    props: route => ({ articleData: route.params.articleData }),
    component: () =>
    import(/* webpackChunkName: "about" */ "@/views/ArticleView"),
},
  


];


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    let scrollTo = 0;

    if (to.hash) {
        scrollTo = to.hash;
    } else if (savedPosition) {
        scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
},
});

export default router;
