<template>
    <div class="help px-3 py-4 flex flex-col gap-3 justify-center items-center">
      <p class="ma-0 pa-0 text-white text-3xl font-bold font-sans text-center">
       {{ title }}
      </p>
      
    </div>
  </template>
  
  <script>
export default {
    name: "banner",
    data: () => ({}),
    components: {},
    props:['title']
  };
</script>
  
  <style scoped>
  .help {
    height: 40vh;
    background: linear-gradient(
        rgba(11, 11, 11, 0.713),
        rgba(14, 14, 14, 0.644)
      ),
      url(@/assets/img/Albino-1.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* backdrop-filter: blur(5px); */
  }
  </style>
  