<template>
  <div>
    <div
      class="flex items-center xs:py-4 px-3 w-full h-full overflow-hidden"
    >
      <div class="img_nav enough">
        <img @click="To()" width="250" :src="userData ? userData.logo : ''" alt="" />
      </div>
      <div class="nav_2 enough xs:hidden md:block">
        <nav class="flex gap-5 items-center justify-center font-bold">
          <router-link :to="{ path: '/' }">Home</router-link>
          <router-link :to="{ path: '/stories' }">Stories</router-link>
          <router-link :to="{ path: '/about' }">About Albinism</router-link>
          <router-link :to="{ path: '/blog' }">Article</router-link>
        </nav>
      </div>
      <div class="nav_3 flex justify-end gap-3 items-center enough">
        <div
          class="w-full xs:hidden md:flex py-4 justify-center items-center gap-5"
        >
          <v-icon color="black" @click="social(userData.facbook)"
            >mdi-facebook</v-icon
          >
          <v-icon color="black" @click="social(userData.instagram)"
            >mdi-instagram</v-icon
          >
          <v-icon @click="social(userData.twitter)">mdi-twitter</v-icon>
        </div>
        <div class="xs:hidden md:flex items-center gap-1">
          <v-btn
            small
            v-if="lang == 'en'"
            @click="Translate('yo')"
            text
            color="blue"
            >Yoruba</v-btn
          >
          <v-btn
            small
            v-if="lang == 'yo'"
            @click="Translate('en')"
            text
            color="blue"
            >English</v-btn
          >
          <v-btn color="red" class="white--text">Support</v-btn>
        </div>

        <div class="xs:block md:hidden">
          <v-btn
            small
            v-if="lang == 'en'"
            @click="Translate('yo')"
            text
            color="blue"
            >Yoruba</v-btn
          >
          <v-btn
            small
            v-if="lang == 'yo'"
            @click="Translate('en')"
            text
            color="blue"
            >English</v-btn
          >
          <v-btn @click="OpenNav" small text fab color="transparent"
            ><v-icon color="red">mdi-menu</v-icon></v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nav } from "@/main.js";

export default {
  name: "navbar",
  data: () => ({
    lang: "",
  }),
  props: ["userData"],
  methods: {
    Translate(lang) {
      localStorage.setItem("lang", lang);
      location.reload();
    },
    To(){
      this.$router.push({path: '/'})
    },

    social(link){
          location.replace(link)
      },

    OpenNav() {
      nav.$emit("nav");
    },
  },
  created() {
    let lang = localStorage.getItem("lang") || "en";
    this.lang = lang;
  },
};
</script>

<style scoped>
.img_nav {
  width: 25% !important;
}

.nav_2 {
  width: 50% !important;
}

.nav_3 {
  width: 25% !important;
}
</style>
