<template>
  <div class="stories py-10 px-4">
    <p class="ma-0 pa-0 text-3xl font-sans font-bold text-center">
      Meet the Albinism Cause Heroes
    </p>
    <p class="ma-0 pa-0 text-lg font-serif text-center">
      Learn uplifting tales from people who have albinism, those who provide
      medical <br />
      assistance to them, and those who fight for their human rights.
    </p>

    <div 
      class="grid xs:grid-cols-1 gap-4 my-4 md:grid-cols-4 place-items-center"
    >
      <div  @click="Open(i)"
        v-for="(i, n) in userData.stories"
        :key="n"
        class="relative hero h-56 w-52 bg-slate-100 shadow-lg"
      >
        <img :src="i.img" class="object-cover w-full h-full" alt="" />
        <div class="text-box flex justify-center items-center">
          <p class="ma-0 pa-0 text-black">{{ i.author }}</p>
        </div>
      </div>
    </div>
    <div class="flex justify-center items-center w-full">
      <v-btn disabled="true" outlined color="red" tile large
        >Share Your Story</v-btn
      >
    </div>

    <v-dialog
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
      v-model="dialog"
    >
      <v-card>
        <v-toolbar dark color="#8c4f0d">
          <v-toolbar-title class="text-white">
            {{ dform.author }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text color="white" @click="dialog = false">
              Close
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="p-5">
          <div class="flex justify-start items-center gap-3">
            <img
              width="300"
              class="relative rounded-md shadow-lg"
              :src="dform.img"
              alt=""
            />
            <h2
              class="text-2xl font-bold leading-tight text-black sm:text-4xl lg:text-4xl"
            >
              {{ dform.name }} <br />
              <p class="italic font-mono font-light text-xl">
                {{ dform.author }}
              </p>
            </h2>
            
          </div>

          <div  v-html="dform.text"
              class="mt-3 pr-4 text-lg leading-relaxed text-gray-600 md:mt-8">
           
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "stories",
  props: ["userData"],
  data: () => ({
    dform: {},
    dialog: false,
  }),
  methods: {
    Open(i){
        this.dialog = true
        this.dform = i
    }
  },
  components: {},
};
</script>

<style scoped>
.text-box {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0;
  background: whitesmoke;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}

.hero:hover .text-box {
  /* transform: translate(-50%, -50%); */
  transition: 0.5s;
  height: 30%;
}
</style>
