<template>
  <div :style="{
    'background': 'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.682)),url('+userData.sectionFive.bgImg+')'
  }
  " class="px-4 py-5 mission flex text-center items-center flex-col gap-4  justify-center">
    <p v-html="userData.sectionFive.mainTitle" class="ma-0 pa-0 text-2xl text-center font-serif text-red font-bold"></p>

    <p v-html="userData.sectionFive.headTitle" class="ma-0 pa-0 text-4xl font-sans text-capitalize font-bold "></p>
    <p v-html="userData.sectionFive.descp" class="ma-0 pa-0  text-md font-light font-serif"></p>
    <div class="w-full grid xs:grid-cols-1 gap-4 place-items-center md:grid-cols-3"
    >

    <div class=" w-2/3 py-3 px-4 text-center gap-3 shadow-md rounded-md bg-white flex flex-col justify-start items-center">
        <p class="text-2xl text-red-600 font-bold font-sans">Mission</p>
        <p v-html="userData.sectionFive.mission" class="ma-0 pa-0 text-black text-md font-light font-serif"></p>

    </div>
    <div class="w-2/3 py-3 px-4 text-center gap-3 shadow-md rounded-md bg-white flex flex-col justify-start items-center">
        <p class="text-2xl text-red-600 font-bold font-sans">Vision</p>
        <p v-html="userData.sectionFive.vision" class="ma-0 pa-0 text-black text-md font-light font-serif"></p>
       
    </div>
    <div class="w-2/3 py-3 px-4 text-center gap-3 shadow-md rounded-md bg-white flex flex-col justify-start items-center">
        <p class="text-2xl text-red-600 font-bold font-sans">Problem Statement</p>
        <p v-html="userData.sectionFive.problemS" class="ma-0 pa-0 text-black text-md font-light font-serif"></p>

    </div>

</div>
  </div>
</template>

<script>
export default {
    name: 'Mission',
    props: ['userData'],
    data: ()=>({

    }),
    methods:{

    },

}
</script>

<style scoped>
.mission{
    min-height: 70vh;
    border-top: 2px solid rgba(128, 128, 128, 0.693);
    border-bottom: 2px solid rgba(128, 128, 128, 0.693);
    color: white;
    /* background: linear-gradient(rgba(16, 15, 15, 0.861), rgba(16, 15, 15, 0.861)), url(@/assets/img/herobg.webp); */
}

</style>