<template>
  <div class="">
    <hero :userData="userData"></hero>
    <aboutVue :userData="userData"></aboutVue>

    <doyouknowVue :userData="userData"></doyouknowVue>
    <storiesVue :userData="userData"></storiesVue>
    <missionVue :userData="userData"></missionVue>
    <blog :articles="userData.articles" :standalone="true"></blog>
    <helpVue :sectionSeven="userData.sectionSeven"></helpVue>

  </div>
</template>

<script>
import aboutVue from "@/components/about.vue";
import blog from "@/components/blog.vue";
import doyouknowVue from "@/components/doyouknow.vue";
import helpVue from "@/components/help.vue";
import hero from "@/components/hero.vue";
import missionVue from "@/components/mission.vue";
import storiesVue from "@/components/stories.vue";

export default {
    name: 'Home',
    props: ['userData'],
    data: ()=>({

    }),
    components: {
        hero,
        aboutVue,
        doyouknowVue,
        blog,
        missionVue,
        storiesVue,
        helpVue
    }
}
</script>

<style scoped>

.one{
    -webkit-mask-image: url(@/assets/img/nija.png);
    mask-image: url(@/assets/img/nija.png);
}

</style>