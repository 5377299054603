<template>
  <div :style="{
    'background': 'linear-gradient(rgba(255, 255, 255, 0.713),rgba(255, 255, 255, 0.644)),url('+sectionSeven.bgImg+')'
  }
  " class="help px-3 py-4 flex flex-col gap-3 justify-center items-center">
    <p v-html="sectionSeven.headTitle" class="ma-0 pa-0 text-3xl font-bold font-sans text-center">
      
    </p>
    <p
    v-html="sectionSeven.headDescrp"
      class="ma-0 pa-0 xs:w-full md:w-1/2 text-lg font-normal text-center font-serif"
    >
      
    </p>
    <div>
      <v-btn block color="red" class="white--text"
        > <p class="ma-0 pa-0"  v-html="sectionSeven.actionbtn" ></p> <v-icon right>mdi-arrow-right</v-icon></v-btn>
    </div>

    <div class="w-full d-flex py-4  justify-center items-center gap-5">
      <v-icon color="black" @click="social(userData.facbook)"
            >mdi-facebook</v-icon
          >
          <v-icon color="black" @click="social(userData.instagram)"
            >mdi-instagram</v-icon
          >
          <v-icon color="black" @click="social(userData.twitter)">mdi-twitter</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "help",
  data: () => ({}),
  components: {},
  methods:{
    social(link){
          location.replace(link)
      },

  },
  props: ['sectionSeven']
};
</script>

<style scoped>
.help {
  height: 60vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.713),
      rgba(255, 255, 255, 0.644)
    ),
    url(@/assets/img/Albino-1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  backdrop-filter: blur(5px);
}
</style>
