<template>
  <div class="bg-white about flex xs:flex-col md:flex-row justify-between px-7 py-8 items-center">
    <div class="flex flex-col gap-3 justify-center items-start">
        <p class="ma-0 pa-0 text-4xl font-sans font-bold text-red-600">About Us</p>
        <p class="ma-0 pa-0" v-html="userData.aboutSection.aboutUsText"></p>

        <v-btn :to="{name: 'About'}" outlined>Read More</v-btn>
    </div>
   
    <img class="abt_img xs:hidden md:block "  :src="userData.aboutSection.aboutUsImg" alt="">
  </div>
</template>

<script>
export default {
  name: 'About',
  props:['userData'],
  methods:{
    FormatText(text){
            let newtext = text.replace(/<[^>]+>/g, "")
            return newtext
        }
  }

}
</script>

<style scoped>
.abt_img{
    width: 55%;
}

</style>