<template>
    <footer class="bg-secondary">
      <div class=" px-6 py-8 mx-auto">
          <div class="text-center flex w-full justify-center items-center xs:flex-col md:flex-row">
              <img width="200" :src="userData ? userData.logo : ''" alt="">
  
  
          </div>
  
          <hr class="my-6 border-gray-200 md:my-10 dark:border-gray-700" />
  
          <div class="flex flex-col items-center justify-center gap-4  text-center">
              <p class="text-black ma-0 pa-0  ">© Copyright 2021. All Rights Reserved.</p>
  
              <div class="flex gap-3 -mx-2">
                 <v-icon color="black" @click="social(userData.facbook)">mdi-facebook</v-icon>  
                 <v-icon color="black" @click="social(userData.instagram)">mdi-instagram</v-icon> 
                 <v-icon @click="social(userData.twitter)">mdi-twitter</v-icon>   
                     
              </div>
          </div>
      </div>
  </footer>
  </template>
  
  <script>
export default {
  name: 'footer',
  props: ['userData'],
  data: ()=>({
  
  }),
  methods:{
      social(link){
          location.replace(link)
      }
  }
  }
</script>
  
  <style>
  
  </style>