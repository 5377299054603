<template>
  <div :style="{
    'background': 'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.682)),url('+userData.heroSection.heroBg+')', 'background-repeat': 'no-repeat', 'background-size': 'cover'
  }
  " class="hero around xs:flex py-3 xs:gap-3 xs:justify-center xs:items-center xs:flex-col md:grid xs:grid-cols-1 md:grid-cols-2 px-5 w-full items-center">

    <div class="hero_heading  md:flex flex-col justify-center xs:text-center md:items-start gap-3 w-full col-span-1">
        <p class="ma-0 pa-0 xs:text-3xl xs:my-2 md:my-0 xs:text-center md:text-left md:text-5xl text-white font-sans font-italic" v-html="userData.heroSection.heroTitle"></p>
        <p class="ma-0 pa-0 mb-4 xs:text-lg xs:text-center xs:my-2 md:my-0  md:text-left md:text-2xl  font-light text-white " v-html="userData.heroSection.heroDescr"></p>
        <v-btn large tile color="white" class="text-red">Support Us</v-btn>
    </div>

    <div class="hero_img xs:order-first flex md:order-last col-span-1 md:flex justify-center items-center ">
        <img width="" class="imghero  rounded-lg shadow-lg" :src="userData.heroSection.heroImg" alt="">
    </div>

  </div>
</template>

<script>
export default {
    name: 'hero',
    props: ['userData'],
    methods:{
        FormatText(text){
            let newtext = text.replace(/<[^>]+>/g, "")
            return newtext
        }
    }
}
</script>

<style scoped>


.hero{
    min-height: 100vh;
    /* background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.682)),url(@/assets/img/test.jpg); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.imghero{
    width: 70%;
}
@media (max-width: 760px) {
    .imghero{
        width: 70%;
    }
    
}

@media (max-width: 600px) {
    .imghero{
        width: 100%;
    }

    .hero{
    min-height: 80vh;
    /* background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.682)),url(@/assets/img/test.jpg); */
    background-repeat: no-repeat !important;
    background-position: center center;
    background-size: cover;
}
    
}

</style>