<template>
  <v-app >
    <div class="flex h-screen justify-center items-start" v-if="skeleton">
    <v-skeleton-loader class="w-full"   type="list-item-avatar, divider, list-item-three-line, card-heading, image,list-item-three-line, image, actions"></v-skeleton-loader>
  </div>
 
    <div v-else>
    <navbar :userData="userData"></navbar>
    <v-main>
      <router-view :articles="userData.articles" :userData="userData" />
    </v-main>
    <v-snackbar v-model="snackbar" timeout="3000" :color="snackColor">
      <div class="flex justify-between items-center px-3 py-1">
        <p class="ma-0 pa-0 text-white font-bold">{{ snackText }}</p>

        <v-btn color="blue" text @click="snackbar = false"> Close </v-btn>
      </div>
    </v-snackbar>

    <footerVue :userData="userData"></footerVue>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list-item  link
          :to="{ path: '/' }"  class="flex justify-center items-center">
        <v-img :src="userData.logo"></v-img>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="{ path: item.url }"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <div class="flex pa-2 w-full justify-center items-center"> <v-btn color="red" tile class="white--text">Support Us</v-btn></div>
       
        <v-divider></v-divider>
        <v-list-item class="w-full">
          <div
            class="w-full py-4 flex justify-center items-center gap-5"
          >
            <v-icon color="black" @click="social(userData.facbook)"
              >mdi-facebook</v-icon
            >
            <v-icon color="black" @click="social(userData.instagram)"
              >mdi-instagram</v-icon
            >
            <v-icon @click="social(userData.twitter)">mdi-twitter</v-icon>
          </div>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
  </v-app>
</template>

<script>
import {
  enroll,
  nav,
  snackbar,
} from "@/main";

import footerVue from "./components/footer.vue";
import navbar from "./components/navbar.vue";
import { crud } from "./services";

export default {
  name: "App",
  components: {
    navbar,
    footerVue,
  },
  created() {
    this.GetSetup();
    snackbar.$on("open", (e) => {
      this.snackText = e.text;
      this.snackColor = e.color;
      this.snackbar = true;
    });

    nav.$on("nav", () => {
      this.drawer = true;
    });
    enroll.$on("enroll", () => {
      this.dialog = true;
    });
  },
  methods: {
    async GetSetup() {
      try {
        const res = await crud.queryDoc("ADMIN", "type", "ADMIN");
        this.userData = res.find((i) => i.type == "ADMIN");
        this.skeleton = false
      } catch (err) {
        console.log(err);
      }
    },
    To(){
      this.$router.push({path: '/'})
    },
    social(link) {
      location.replace(link);
    },
  },
  data: () => ({
    drawer: null,
    items: [
      { title: "Home", icon: "mdi-home", url: "/" },
      { title: "About Albinism", icon: "mdi-forum", url: "/about" },
      { title: "Stories", icon: "mdi-account", url: "/Stories" },
      { title: "Articles", icon: "mdi-forum", url: "/blog" },
    ],
    userData: {},
    skeleton: true,
    snackColor: "",
    snackbar: false,
    snackText: "",
  }),
};
</script>
